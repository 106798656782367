import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { Navigate, useParams } from 'react-router-dom';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import {
  FormOrderType,
  FormSigningState,
  MaterialisedPropertyData,
  TransactionMetaData
} from '@property-folders/contract';
import { MasterRootKey } from '@property-folders/contract/yjs-schema/property';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ShortId } from '@property-folders/common/util/url';
import { PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { useStore } from 'react-redux';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { FormContext, FormContextDefaultValue } from '@property-folders/components/context/FormContext';
import { propertyFolder } from '@property-folders/contract/yjs-schema/model/field';
import { FormUtil } from '@property-folders/common/util/form';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import * as Y from 'yjs';
import { IndexeddbPersistence } from 'y-indexeddb';
import { Awareness } from 'y-protocols/awareness';
import { ConfigureSubscriptionFolderSigning } from '~/pages/ConfigureSubscriptionFolderSigning';
import { useSigningNavProps } from '@property-folders/components/hooks/useSigningNavProps';
import { SubscriptionFormOutForSigning } from '~/components/SubscriptionFormOutForSigning';
import { cancelSigning } from '@property-folders/components/subscription-forms/cancelSigning';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';

interface RouterData {
  transId: string,
  ydoc: Y.Doc,
  localProvider: IndexeddbPersistence,
  ydocStats: Y.Doc,
  localProviderStats: IndexeddbPersistence,
  awareness: Awareness,
}

export function SubscriptionFormDocument() {
  const { ydoc } = useReactRouterData<RouterData>();
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { documentIdRaw } = useParams();
  const {
    bindState: metaBindState,
    binder: metaBinder
  } = useImmerYjs<TransactionMetaData>(ydoc, MasterRootKey.Meta);
  const {
    binder: dataBinder
  } = useImmerYjs<MaterialisedPropertyData>(ydoc, MasterRootKey.Data);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const [formCode, setFormCode] = useState('');
  const formId = ShortId.toUuid(documentIdRaw);
  const formInstance = formCode
    ? PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, meta || {})
    : PropertyFormYjsDal.searchFormInstanceByIdFromState(formId, meta || {});
  const store = useStore();
  const { form, signing } = useMemo(() => {
    const form = FormUtil.getFormState(formCode, formId, meta);
    return {
      form,
      signingState: form?.signing?.state || FormSigningState.None,
      signing: form?.signing,
      order: form?.order
    };
  }, [meta, formCode, formId]);

  const {
    showConfiguration,
    showSigningSession
  } = useSigningNavProps({ signing, formCode });

  const formContext = useMemo<FormContextType>(() => {
    return {
      ...FormContextDefaultValue,
      formName: formInstance?.formCode || '',
      transactionRules: propertyFolder,
      formId,
      authRepMode: formInstance?.order?.type === FormOrderType.Filler
    };
  }, [formInstance, formId]);

  const cancelSigningHandler = useCallback(() => {
    AjaxPhp.updateSubscriptionFolderStatus({
      documentId: formInstance?.subscription?.documentId as number,
      status: FormSigningState.None
    }).then(() => {
      cancelSigning({
        formId,
        formCode,
        sessionInfo,
        metaBinder,
        dataBinder,
        store
      });
    });
  }, []);

  useEffect(() => {
    if (!formInstance?.formCode) return;

    setFormCode(formInstance.formCode);
  }, [formInstance?.formCode]);

  if (!formCode || !form) {
    return <></>;
  }

  return <FormContext.Provider value={formContext}>
    <ContentTitler
      breadcrumbs={[]}
      title={formContext.wizardTitle}
      titleBadge={<></>}
      afterBreadcrumbs={<></>}
      afterTitle={<></>}
      flex={true}
    >
      {showConfiguration && <ConfigureSubscriptionFolderSigning />}
      {showSigningSession && <
        SubscriptionFormOutForSigning
        yDoc={ydoc}
        formInstance={form}
        onCancel={cancelSigningHandler}
      />}
      {!showConfiguration && !showSigningSession &&
        <Navigate to={`/forms.php?DocumentID=${formInstance?.subscription?.documentId}`} />}
    </ContentTitler>
  </FormContext.Provider>;
}
