import React, { useContext, useMemo } from 'react';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { createBrowserRouter, LoaderFunctionArgs, Navigate, Outlet, redirect, RouterProvider } from 'react-router-dom';
import { ErrorHandlerPage } from '~/pages/ErrorHandlerPage';
import { ClauseManagementPage } from '~/pages/ClauseManagementPage';
import { PropertiesPage } from '~/pages/PropertiesPage';
import { TransactionNew } from '~/pages/TransactionNew';
import { PropertyFromEnvelope } from '~/pages/PropertyFromEnvelope';
import { ShortId } from '@property-folders/common/util/url';
import { buildYDoc } from '@property-folders/components/form-gen-util/buildYDoc';
import { PropertyBackgroundJobs } from '@property-folders/components/context/PropertyBackgroundJobs';
import { TransactionHomePage } from '~/pages/TransactionHomePage';
import { TransactionAudit } from '~/pages/TransactionAudit';
import { ContractManagement } from '~/pages/ContractManagement';
import { SubscriptionFormEditPage } from '~/pages/SubscriptionFormEditPage';
import { SigningPage } from '~/pages/SigningPage';
import { ExternalPropertyUpdateReviewPage } from '~/pages/ExternalPropertyUpdateReviewPage';
import { ProspectivePurchasersPage } from '~/pages/ProspectivePurchasersPage';
import { ProspectivePurchasersAddPage } from '~/pages/ProspectivePurchasersAddPage';
import { OfferManagementPage } from '~/pages/OfferManagementPage';
import { OrderPage } from '~/pages/OrderPage';
import { SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import { EpfIframePage } from '~/pages/EpfIframePage';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { PhpPage } from '~/pages/PhpPage';
import LoginPage from '~/LoginPage';
import { FullPageLoading } from '@property-folders/components/dragged-components/FullPageLoading';
import { AgentInfoState } from '~/App';
import { AppContainer } from '~/AppContainer';
import { FormsListPage } from '~/pages/FormsListPage';
import { CreateFormPage } from '~/pages/CreateFormPage';
import {
  ExtraFormCode,
  FormCode,
  FormSigningState,
  MasterRootKey,
  TransactionMetaData
} from '@property-folders/contract/yjs-schema/property';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { RumPageSubscriber } from '@property-folders/components/telemetry/RumPageSubscriber';
import { RumAgentSession } from '@property-folders/components/telemetry/RumAgentSession';
import { PropertyFolderReport } from '~/pages/PropertyFolderReport';
import { EntitySettingsEntitySelectPage } from '~/pages/settings/EntitySettingsEntitySelectPage';
import { EntitySettingsNavMenuPage } from '~/pages/settings/EntitySettingsNavMenuPage';
import { EntitySettingsEditPage } from '~/pages/settings/EntitySettingsEditPage';
import { Container } from 'react-bootstrap';
import { GenerateSubscriptionFolderSigningSession } from '~/pages/GenerateSubscriptionFolderSigningSession';
import { SubscriptionFolder } from '~/pages/SubscriptionFolder';
import { SubscriptionFormDocument } from '~/pages/SubscriptionFormDocument';

export function RoutedApp({ onLogoutClick, sessionState, agentInfo, needsReload, forceTelemetryCollection }: {
  onLogoutClick: () => void,
  sessionState?: string,
  agentInfo: AgentInfoState,
  needsReload: boolean,
  forceTelemetryCollection: boolean
}) {
  const {
    instance: yManagerInstance,
    loaded: yManagerLoaded
  } = useContext(YManagerContext);
  const { instance: fileSync } = useContext(FileSyncContext);

  async function loadYdocMatch(match: LoaderFunctionArgs) {
    const {
      params: {
        propertyIdRaw
      }
    } = match;
    const transId = ShortId.toUuid(propertyIdRaw);
    const { ydoc, localProvider } = buildYDoc(transId);
    await localProvider.whenSynced;

    return { ydoc, localProvider, transId };
  }

  const router = useMemo(() => createBrowserRouter([
    {
      path: '',
      errorElement: <ErrorHandlerPage />,
      children: [
        {
          path: '/',
          element: <Navigate to='/index.php' />
        },
        {
          path: 'clauses',
          element: <AppContainer onLogoutClick={onLogoutClick}><ClauseManagementPage /></AppContainer>
        },
        {
          path: 'agency-settings',
          element: <AppContainer onLogoutClick={onLogoutClick}>
            <EntitySettingsEntitySelectPage>
              <Outlet />
            </EntitySettingsEntitySelectPage>
          </AppContainer>,
          children: [{
            index: true,
            element: <Container fluid className='d-flex flex-row justify-content-center align-items-center h-100 g-0'>
              <div>
                Select an agency above to get started
              </div>
            </Container>
          }, {
            path: ':entityUuidRaw',
            element: <EntitySettingsNavMenuPage>
              <Outlet />
            </EntitySettingsNavMenuPage>,
            children: [{
              path: ':settingsGroup',
              element: <EntitySettingsEditPage />
            }]
          }]
        },
        {
          path: 'properties',
          element: <AppContainer onLogoutClick={onLogoutClick}><PropertiesPage /></AppContainer>
        },
        {
          path: 'properties/new',
          element: <AppContainer onLogoutClick={onLogoutClick}><TransactionNew /></AppContainer>
        },
        {
          path: 'subscription-folder/generate/:documentId',
          element: <AppContainer onLogoutClick={onLogoutClick}><GenerateSubscriptionFolderSigningSession /></AppContainer>
        },
        {
          path: 'subscription-folder/:propertyIdRaw',
          loader: async (match) => {
            if (!yManagerInstance) {
              throw new Error('YManager instance not found!');
            }
            const {
              params: {
                propertyIdRaw
              }
            } = match;
            const transId = ShortId.toUuid(propertyIdRaw);
            const { ydoc, localProvider, ydocStats, localProviderStats } = buildYDoc(transId);
            const awareness = yManagerInstance.bindAwareness(ydoc);
            const cleanup = () => {
              yManagerInstance.unbindAwareness(ydoc);
            };
            yManagerInstance.markUserViewing(transId, true);
            // localProvider is awaited rather than the buildYdoc whenSynced,
            // as to not scupper offline functionality
            await localProvider.whenSynced;
            await localProviderStats.whenSynced;

            return {
              transId,
              ydoc,
              ydocStats,
              localProvider,
              localProviderStats,
              awareness,
              cleanup
            };
          },
          element: <AppContainer onLogoutClick={onLogoutClick}>
            <SubscriptionFolder />
          </AppContainer>,
          children: [
            {
              path: 'document/:documentIdRaw',
              element: <SubscriptionFormDocument />
            }
          ]
        },
        {
          path: 'properties/from-envelope/:envelopeId',
          element: <AppContainer onLogoutClick={onLogoutClick}><PropertyFromEnvelope /></AppContainer>
        },
        {
          // this could be of the following forms
          // * {uuid}
          // * {shortid}
          // * property-address-bits-{uuid}
          // * property-address-bits-{shortid}
          path: 'properties/:propertyIdRaw',
          loader: async (match) => {
            if (!yManagerInstance) {
              throw new Error('YManager instance not found!');
            }
            const {
              params: {
                propertyIdRaw
              }
            } = match;
            const transId = ShortId.toUuid(propertyIdRaw);
            const { ydoc, localProvider, ydocStats, localProviderStats } = buildYDoc(transId);
            const awareness = yManagerInstance.bindAwareness(ydoc);
            const cleanup = () => {
              yManagerInstance.unbindAwareness(ydoc);
            };
            yManagerInstance.markUserViewing(transId, true);
            // localProvider is awaited rather than the buildYdoc whenSynced,
            // as to not scupper offline functionality
            await localProvider.whenSynced;
            await localProviderStats.whenSynced;

            return {
              transId,
              ydoc,
              ydocStats,
              localProvider,
              localProviderStats,
              awareness,
              cleanup
            };
          },
          element: <AppContainer onLogoutClick={onLogoutClick}>
            <PropertyBackgroundJobs useLoader={true}>
              <Outlet />
            </PropertyBackgroundJobs>
          </AppContainer>,
          children: [
            {
              index: true,
              element: <TransactionHomePage />
            },
            {
              path: 'audit',
              element: <TransactionAudit />
            },
            {
              path: 'document/:documentIdRaw',
              element: <TransactionHomePage />
            },
            {
              path: 'contracts/template',
              element: <TransactionHomePage special='templateContract' />
            },
            {
              path: 'contracts/:documentIdRaw',
              element: <TransactionHomePage special='vendorToSignContract' />
            },
            {
              path: 'contracts',
              element: <ContractManagement />
            },
            {
              path: 'subscription/:documentIdRaw',
              loader: async (match) => {
                const {
                  params: {
                    documentIdRaw
                  }
                } = match;

                const formId = ShortId.toUuid(documentIdRaw);

                return {
                  formId
                };
              },
              element: <SubscriptionFormEditPage />
            },
            {
              path: 'sign/:documentIdRaw/as/:partyIdRaw',
              loader: async (match) => {
                const {
                  params: {
                    documentIdRaw,
                    partyIdRaw
                  }
                } = match;

                const formId = ShortId.toUuid(documentIdRaw);
                const partyId = ShortId.toUuid(partyIdRaw);

                return {
                  formId,
                  partyId
                };
              },
              element: <SigningPage />
            },
            {
              path: 'review-external-update',
              element: <ExternalPropertyUpdateReviewPage />
            },
            {
              path: 'prospective-purchasers',
              element: <ProspectivePurchasersPage />
            },
            {
              path: 'prospective-purchasers/add',
              element: <ProspectivePurchasersAddPage />
            },
            {
              path: 'documents',
              element: <FormsListPage fromNewPropertyFolder={true} />
            },
            {
              path: 'documents/create',
              element: <CreateFormPage propertyFolder={true} />
            },
            {
              path: 'offer-management',
              element: <OfferManagementPage />,
              children: [{
                path: ':purchaserIdRaw',
                element: <OfferManagementPage />
              }]
            },
            {
              path: 'linkcreate/subsequent',
              loader: async (match) => {
                const { ydoc, transId } = await loadYdocMatch(match);
                const metadata = ydoc.getMap(MasterRootKey.Meta).toJSON() as TransactionMetaData;
                const headline = generateHeadlineFromMaterialisedData(ydoc.getMap(MasterRootKey.Data).toJSON());
                const saaFam = metadata?.formStates?.[FormCode.RSAA_SalesAgencyAgreement];
                const existingUnsignedSubsequents = saaFam?.instances
                  ?.filter(inst => inst.formCode === ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent)
                  ?.filter(inst => inst.signing?.state !== FormSigningState.Signed);
                if (!Array.isArray(existingUnsignedSubsequents)) {
                  return redirect(LinkBuilder.propertyPath({ id: transId, nicetext: headline }));
                }

                if (existingUnsignedSubsequents.length) {
                  // In general, there should only be one unsigned subsequent, so we'll just pick the last one in the
                  // list
                  const useInst = existingUnsignedSubsequents[existingUnsignedSubsequents.length - 1];
                  return redirect(LinkBuilder.documentPath(
                    { id: transId, nicetext: headline },
                    { id: useInst.id, nicetext: FormTypes[useInst.formCode].label },
                    false
                  ));
                }

                const {
                  clauseChildId,
                  formId,
                  documentId
                } = await handleNewForm(ydoc, ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent, fileSync) ?? {};
                if (!formId) throw new Error('Form not generated');
                return redirect(LinkBuilder.documentPath(
                  { id: transId, nicetext: headline },
                  { id: formId, nicetext: FormTypes[ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent].label },
                  false
                ));
              },
              element: <FullPageLoading textMessage='Generating form...' />,
              errorElement: <FullPageLoading textMessage='Could not generate form' />
            },
            {
              path: 'order/form-1',
              loader: async (match) => {
                // I have not yet found how to obtain the ydoc we got as part of the loader above
                // this so that we don't have to re-retrieve it here
                // Answer is here
                // https://remix.run/docs/en/1.19.3/pages/faq#how-can-i-have-a-parent-route-loader-validate-the-user-and-protect-all-child-routes
                // It's just not available

                const { ydoc, transId } = await loadYdocMatch(match);

                // At the moment we do not look for form 1 ordering state data in sublineages
                const metadata = ydoc.getMap(MasterRootKey.Meta).toJSON() as TransactionMetaData;
                const form1State = metadata.formStates?.[FormCode.Form1];
                const insts = form1State?.instances;
                if (!(insts && Array.isArray(insts) && insts.length)) {
                  return null;
                }
                const latestInstance = insts[insts.length - 1];
                if (!latestInstance) {
                  return null;
                }
                const headline = generateHeadlineFromMaterialisedData(ydoc.getMap(MasterRootKey.Data).toJSON());
                const formType = FormTypes[latestInstance.formCode];
                return redirect(LinkBuilder.documentPath({ id: transId, nicetext: headline }, {
                  id: latestInstance.id,
                  nicetext: formType.label
                }, !!formType.subscription));
              },
              element: <OrderPage formCode={SubscriptionFormCode.SAF001V2_Form1} />
            }
          ]
        },
        {
          path: 'form1_dashboard.php',
          element:
            <AppContainer onLogoutClick={onLogoutClick}>
              <EpfIframePage
                url={LinkBuilder.reaformsFromRoot('/form1_dashboard.php')}
                needsReload={needsReload}
              />
            </AppContainer>,
          loader: (match: any) => {
            const params = new URLSearchParams(new URL(match.request.url).search);
            return {
              additionalQueryParams: Object.fromEntries(params)
            };
          }
        },

        {
          path: 'formscreated.php',
          element: <Navigate to='/forms' />
        },

        {
          path: 'forms',
          element: <AppContainer onLogoutClick={onLogoutClick}><FormsListPage /></AppContainer>
        },

        {
          path: 'forms/create',
          element: <AppContainer onLogoutClick={onLogoutClick}><CreateFormPage /></AppContainer>
        },

        {
          path: 'admin/reports/property-folders',
          element: <AppContainer onLogoutClick={onLogoutClick}><PropertyFolderReport /></AppContainer>
        },

        // php routes
        ...[
          'index.php',
          'formsopen.php',
          'formsnew.php',
          'user_profile.php',
          'emailstatus.php',
          'esignstatus.php',
          'formsarchived.php',
          'forms_import.php',
          'savedforms.php',
          'user_form_templates.php',
          'user_email_templates.php',
          'clauses.php',
          'change_password.php',
          'admin/admin_devform.php',
          'admin/admin_users.php',
          'admin/admin_agents.php',
          'admin/admin_trials.php',
          'admin/admin_formlist.php',
          'admin/admin_formstats.php',
          'admin/admin_allformscreated.php',
          'admin/admin_topformslist.php',
          'admin/admin_topagencylist.php',
          'admin/admin_topformslist_agency.php',
          'admin/admin_agencystats.php',
          'admin/admin_report.php',
          'admin/admin_email_templates.php',
          'admin/admin_profile.php',
          'admin/admin_monthly.php',
          'admin/admin_oasis.php',
          'admin/admin_titles.php',
          'admin/admin_smsstats.php',
          'admin/admin_emailstats.php',
          'admin/admin_formscreatedstats.php',
          'admin/admin_form1stats.php',
          'admin/admin_expired_subscriptions.php',
          'admin/admin_expired_trials.php',
          'admin/admin_clauses_groups.php',
          'admin/admin_subscriptions.php',
          'admin/admin_auditview.php',
          'remotecompletion.php',
          'remotesign.php',
          'remotecompleted.php',
          'epf_logout.php'
        ].map(r => ({
          path: r,
          element: <AppContainer onLogoutClick={onLogoutClick}>
            <PhpPage url={LinkBuilder.reaformsFromRoot(`/${r}`)} />
          </AppContainer>,
          loader: (match: any) => {
            const params = new URLSearchParams(new URL(match.request.url).search);
            return {
              additionalQueryParams: Object.fromEntries(params)
            };
          }
        })),

        // php routes with no sidebar
        ...[
          'forms.php'
        ].map(r => ({
          path: r,
          element: <AppContainer onLogoutClick={onLogoutClick} showSideBar={false}>
            <PhpPage url={LinkBuilder.reaformsFromRoot(`/${r}`)} needsReload={needsReload} />
          </AppContainer>,
          loader: (match: any) => {
            const params = new URLSearchParams(new URL(match.request.url).search);
            return {
              additionalQueryParams: Object.fromEntries(params)
            };
          }
        })),

        // redirects
        {
          path: 'login',
          element: sessionState === 'success' ? <Navigate to='/' /> : <LoginPage />
        },
        {
          path: 'propertyfolders.php',
          element: <Navigate to='/properties' />
        },
        {
          path: '/',
          index: true,
          element: <Navigate to='/index.php' />
        },
        {
          path: '',
          index: true,
          element: <Navigate to='/index.php' />
        }
      ]
    }]
  ), [onLogoutClick]);

  if (!yManagerLoaded) {
    return <FullPageLoading />;
  }

  if (!(agentInfo.agentId && agentInfo.agentUuid)) {
    return <FullPageLoading />;
  }

  return <>
    <RouterProvider router={router} />
    <RumPageSubscriber router={router} />
    <RumAgentSession sessionMeta={agentInfo} forceCollection={forceTelemetryCollection} />
  </>;
}
