import * as Y from 'yjs';
import { Awareness } from 'y-protocols/awareness';
import { IndexeddbPersistence } from 'y-indexeddb';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { startSigningConfiguration } from '@property-folders/components/subscription-forms/startSigningConfiguration';
import { cancelSigning } from '@property-folders/components/subscription-forms/cancelSigning';
import { prepareForSigning } from '@property-folders/components/subscription-forms/prepareForSigning';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { useMediaQuery } from 'react-responsive';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import {
  Agent,
  AwarenessData,
  FormOrderState,
  FormOrderType,
  FormSigningState,
  MaterialisedPropertyData,
  TransactionMetaData
} from '@property-folders/contract';
import { SetupNetStateContext } from '@property-folders/components/dragged-components/NetStateContext';
import { FormCodeUnion, MasterRootKey } from '@property-folders/contract/yjs-schema/property';
import {
  FormContextSigningOverride,
  SetupNetStateWritingYjsDocContext
} from '@property-folders/components/form-gen-util/yjsStore';
import { SetupDebouncedAwarenessContext } from '@property-folders/components/context/DebouncedAwarenessContext';
import { handleNewFormOrder, initialPresence } from '~/pages/TransactionHomePage';
import { RoomProvider } from '@y-presence/react';
import { Badge, Button, InputGroup, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { propertyFolder } from '@property-folders/contract/yjs-schema/model/field';
import { cancelFormOrder, FormUtil, generateInitiator } from '@property-folders/common/util/form';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { useSigningNavProps } from '@property-folders/components/hooks/useSigningNavProps';
import { useEntityLogoForCurrentPropertyFolder } from '@property-folders/components/hooks/useEntityLogo';
import { useEntities } from '@property-folders/components/hooks/useEntity';
import { PDFViewer } from '@property-folders/components/dragged-components/PDFViewer/PDFViewer';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { usePdfPreviewUrl } from '@property-folders/components/dragged-components/subscriptionForms/hooks';
import './SubscriptionFormEditPage.scss';
import { SigningConfiguration } from '@property-folders/components/dragged-components/signing/SigningConfiguration';
import { EpfOrderForm1 } from '@property-folders/components/dragged-components/ordering/EpfOrderForm1';
import {
  ThirdPartyPreparingOrder
} from '@property-folders/components/dragged-components/ordering/ThirdPartyPreparingOrder';
import { ReturnToPreparerModal } from '@property-folders/components/dragged-components/ordering/ReturnToPreparerModal';
import { ReleaseToAgentModal } from '@property-folders/components/dragged-components/ordering/ReleaseToAgentModal';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { FormContext, FormContextDefaultValue } from '@property-folders/components/context/FormContext';
import { useStore } from 'react-redux';
import { UpdatesPendingBadge } from '@property-folders/components/display/properties/UpdatesPendingBadge';
import { PropertyBackgroundJobsContext } from '@property-folders/components/context/PropertyBackgroundJobs';
import {
  EditSubscriptionForm
} from '@property-folders/components/display/properties/subscription-forms/EditSubscriptionForm';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { useBreakpointValue } from '@property-folders/components/hooks/useBreakpointValue';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '@property-folders/components/display/errors/modals';
import { stringifySaleAddress } from '@property-folders/common/util/stringifySaleAddress';
import { SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import { UserPreferencesRootKey } from '@property-folders/contract/yjs-schema/user-preferences';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { companyTradingAs } from '@property-folders/common/util/formatting/functions/companyTradingAs';
import { Form1UnarchiveModal } from '~/pages/forms/Form1UnarchiveModal';
import { SubscriptionFormOutForSigning } from '~/components/SubscriptionFormOutForSigning';

interface RouterData {
  formId: string,
  transId: string,
  ydoc: Y.Doc,
  localProvider: IndexeddbPersistence,
  ydocStats: Y.Doc,
  localProviderStats: IndexeddbPersistence,
  awareness: Awareness,
}

enum AllotmentPaneMode {
  Both = 0,
  Content = 1,
  Preview = 2
}

/**
 * Establishes yjs context, etc.
 */
export function SubscriptionFormEditPage() {
  const { formId, transId, ydoc, awareness } = useReactRouterData<RouterData>();
  const location = useLocation();
  // Not supporting sublineages of subscription forms
  const { bindState: bindMetaState } = useImmerYjs<TransactionMetaData>(ydoc, MasterRootKey.Meta);
  const { data: transMeta } = bindMetaState<TransactionMetaData>(meta => meta);
  const [formCode, setFormCode] = useState('');
  const formInstance = formCode
    ? PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, transMeta || {})
    : PropertyFormYjsDal.searchFormInstanceByIdFromState(formId, transMeta || {});

  useEffect(() => {
    if (!formInstance?.formCode) return;

    setFormCode(formInstance.formCode);
  }, [formInstance?.formCode]);

  const formContext = useMemo<FormContextType>(() => {
    return {
      ...FormContextDefaultValue,
      formName: formInstance?.formCode || '',
      transactionRules: propertyFolder,
      formId,
      authRepMode: formInstance?.order?.type === FormOrderType.Filler
    };
  }, [formInstance]);

  if (!formInstance) {
    return <></>;
  }

  return <SetupNetStateContext ydoc={ydoc} transactionRootKey={MasterRootKey.Data}>
    <SetupNetStateWritingYjsDocContext
      ydoc={ydoc}
      awareness={awareness}
      docName={transId}
      transactionRootKey={MasterRootKey.Data} // Subscription forms do not yet support sublineages
      transactionMetaRootKey={MasterRootKey.Meta}
    >
      <RoomProvider<AwarenessData> awareness={awareness} initialPresence={initialPresence}>
        <SetupDebouncedAwarenessContext delay={0}>
          <FormContext.Provider value={formContext}>
            {(() => {
              if (formInstance.order?.type === FormOrderType.Filler) {
                return <SubscriptionFormEditPageInner transId={transId} formId={formId} formCode={formInstance.formCode}
                  yDoc={ydoc} />;
              }
              const params = new URLSearchParams(location.search || '');
              const viewOrderOverride = params.get('view_order') === '1';
              switch (formInstance.order?.state) {
                case FormOrderState.ClientOrdering:
                  return <EpfOrderForm1 yDoc={ydoc} transId={transId} formId={formId}
                    formCode={formInstance.formCode} />;
                case FormOrderState.ThirdPartyPreparing:
                case FormOrderState.ReturnedToThirdParty:
                case FormOrderState.Cancelled:
                  return <ThirdPartyPreparingOrder yDoc={ydoc} transId={transId} formId={formId}
                    formCode={formInstance.formCode} />;
                case FormOrderState.ReturnedToClient:
                default:
                  if (viewOrderOverride) {
                    return <ThirdPartyPreparingOrder yDoc={ydoc} transId={transId} formId={formId}
                      formCode={formInstance.formCode} />;
                  }
                  return <SubscriptionFormEditPageInner transId={transId} formId={formId}
                    formCode={formInstance.formCode} yDoc={ydoc} />;
              }
            })()}
          </FormContext.Provider>
        </SetupDebouncedAwarenessContext>
      </RoomProvider>
    </SetupNetStateWritingYjsDocContext>
  </SetupNetStateContext>;
}

/**
 * Selects which component to display
 */
function SubscriptionFormEditPageInner({
  formId,
  transId,
  formCode,
  yDoc
}: {
  transId: string,
  formId: string,
  formCode: FormCodeUnion,
  yDoc: Y.Doc
}) {
  const splitEnabled = useMediaQuery({ minWidth: BP_MINIMA.xl });
  const navigate = useNavigate();
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ 'myPath': '' });
  const {
    bindState: metaBindState,
    binder: metaBinder
  } = useImmerYjs<TransactionMetaData>(yDoc, MasterRootKey.Meta);
  const {
    binder: dataBinder,
    bindState: dataBindState
  } = useImmerYjs<MaterialisedPropertyData>(yDoc, MasterRootKey.Data);
  const backgroundJobsContext = useContext(PropertyBackgroundJobsContext);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const { data: propertyData } = dataBindState(d => d);
  const { instance: fileSync } = useContext(FileSyncContext);

  const store = useStore();
  const memberEntities = useEntities();
  const localEntity = memberEntities && meta?.entity?.id ? memberEntities?.[meta.entity.id] : null;
  const currentAgent = localEntity.salespeople?.find(s => s.id === sessionInfo.agentId);
  const renderTextLayer = useBreakpointValue({ base: false, sm: true }, true);

  const tryUseAuthRep = (!!propertyData?.authRep || propertyData?.needsAuthRep) ?? false;
  const agent: Agent | undefined = tryUseAuthRep && sessionInfo && localEntity && currentAgent
    ? {
      id: sessionInfo.entities.find(e => e.entityId === meta?.entity?.id)?.entityUuid as string,
      abn: localEntity.abn,
      rla: localEntity.rla,
      linkedEntityId: localEntity.entityId,
      profileName: localEntity.profileName,
      company: companyTradingAs(localEntity.name, localEntity.tradeName),
      address: localEntity.addressDisplay || stringifySaleAddress({
        streetAddr_parts: {
          StreetName: localEntity.address1,
          Suburb: localEntity.suburb,
          Postcode: localEntity.postcode,
          State: localEntity.state
        }
      }),
      email: localEntity.email,
      phone: localEntity.phone,
      salesp: [{
        id: sessionInfo.agentUuid,
        email: currentAgent.email,
        name: currentAgent.name,
        phone: currentAgent.phone,
        linkedSalespersonId: currentAgent.id
      }],
      form1: {
        ...localEntity.form1,
        serviceFaxOrEmail: currentAgent.form1?.serviceFaxOrEmail || localEntity.form1?.serviceFaxOrEmail,
        serviceAddress: currentAgent.form1?.serviceAddress || localEntity.form1?.serviceAddress,
        serviceAddressIsRla: currentAgent.form1?.serviceAddress ? currentAgent.form1?.serviceAddressIsRla : localEntity.form1?.serviceAddressIsRla
      }
    }
    : undefined;

  const { form, signingState, signing, order } = useMemo(() => {
    const form = FormUtil.getFormState(formCode, formId, meta);
    return {
      form,
      signingState: form?.signing?.state || FormSigningState.None,
      signing: form?.signing,
      order: form?.order
    };
  }, [meta]);
  const narrowMode = useMediaQuery({ maxWidth: BP_MINIMA.sm });
  const headlineVal = generateHeadlineFromMaterialisedData(transRoot, narrowMode);
  const breadcrumbs = useMemo(() => [
    { label: 'Properties', href: '/properties/' },
    {
      label: headlineVal || 'Property Overview',
      href: `/properties/${LinkBuilder.seoFriendlySlug(transId, headlineVal)}`
    },
    { label: 'Edit Subscription Document' }
  ], [headlineVal]);
  const editSubscriptionFormRef = useRef<{
    preview: () => void;
    annexures: () => void
  }>();
  const {
    signingSessionWizardPropsForSidebar,
    showConfiguration,
    showSigningSession,
    partyGroups,
    serveToPurchaserProps,
    signingMainProps
  } = useSigningNavProps({ signing, formCode });

  const entityLogoUri = useEntityLogoForCurrentPropertyFolder();
  const previewHandler = useCallback(() => {
    if (editSubscriptionFormRef.current?.preview) {
      return editSubscriptionFormRef.current?.preview();
    }
    if (splitEnabled) {
      return setAllotmentPaneMode(AllotmentPaneMode.Both);
    }
    return setAllotmentPaneMode(AllotmentPaneMode.Preview);

  }, [editSubscriptionFormRef.current, splitEnabled]);
  const annexuresHandler = useCallback(() => {
    if (editSubscriptionFormRef.current?.annexures) {
      return editSubscriptionFormRef.current.annexures();
    }
  }, [editSubscriptionFormRef.current]);

  const { instance: yManagerInstance } = useContext(YManagerContext);
  const getCurrentUserPrefs = useCallback(() => yManagerInstance?.getUserPrefs()?.doc.getMap(UserPreferencesRootKey.Main).toJSON(), [yManagerInstance]);

  const prepareForSigningHandler = async () => {
    await prepareForSigning({
      dal: new PropertyFormYjsDal(yDoc, MasterRootKey.Data, MasterRootKey.Meta), // No sublineages on subscription forms
      formId,
      formCode,
      sessionInfo,
      initiator: generateInitiator(meta, sessionInfo, localEntity),
      store,
      fileSync,
      getUserPrefsData: getCurrentUserPrefs,
      memberEntities
    });
  };

  const cancelSigningHandler = useCallback(() => {
    cancelSigning({
      formId,
      formCode,
      sessionInfo,
      metaBinder,
      dataBinder,
      store
    });
  }, []);

  const [showReturnModal, setShowReturnModal] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [showOrderConfirmDialog, setShowOrderConfirmDialog] = useState(false);
  const [showReleaseModal, setShowReleaseModal] = useState(false);
  const [showUnArchiveModal, setShowUnArchiveModal] = useState(false);

  const location = useLocation();

  // control allotment pane

  const [allotmentPaneMode, setAllotmentPaneMode] = useState(splitEnabled ? AllotmentPaneMode.Both : AllotmentPaneMode.Content);
  const onVisibleChange = useCallback((changedIndex: number, changedNowVisible: boolean) => {
    if (!changedNowVisible) {
      return setAllotmentPaneMode(changedIndex === 0 ? AllotmentPaneMode.Preview : AllotmentPaneMode.Content);
    }
    if (splitEnabled) {
      return setAllotmentPaneMode(AllotmentPaneMode.Both);
    }

    setAllotmentPaneMode(changedIndex === 0 ? AllotmentPaneMode.Content : AllotmentPaneMode.Preview);
  }, [splitEnabled]);
  useEffect(() => {
    if (splitEnabled) {
      setAllotmentPaneMode(AllotmentPaneMode.Both);
    } else {
      setAllotmentPaneMode(AllotmentPaneMode.Content);
    }
  }, [splitEnabled]);
  const showContentPane = allotmentPaneMode === AllotmentPaneMode.Both || allotmentPaneMode === AllotmentPaneMode.Content;
  const showPreviewPane = allotmentPaneMode === AllotmentPaneMode.Both || allotmentPaneMode === AllotmentPaneMode.Preview;
  const pdfPreviewUrl = usePdfPreviewUrl({
    form,
    yDoc,
    formCode,
    formId
  });

  if (!form?.subscription) {
    console.log('no subscription what!?');
    return <></>;
  }

  const handleOrderClick = () => {
    if (order) return;
    setShowOrderConfirmDialog(true);
  };

  const handleOrderConfirmClick = async () => {
    if (order) return;
    cancelFormOrder(yDoc, formCode, formId);
    const formType = FormTypes[formCode];

    const newId = await handleNewFormOrder(yDoc, formCode);

    navigate(LinkBuilder.documentPath(
      {
        id: transId,
        nicetext: headlineVal
      },
      {
        id: newId,
        nicetext: formType.label
      },
      true));
  };

  const handleUnarchiveClick = () => {
    setShowUnArchiveModal(true);
  };

  const title = FormTypes[formCode].label;
  const titleBadge = form.archived ? <Badge bg={'secondary'} style={{ fontSize: 12 }}>archived</Badge> : undefined;
  const showPreviewButton = !showConfiguration && (signingState === FormSigningState.None || allotmentPaneMode === AllotmentPaneMode.Content);
  const fillerReturned = (order?.type === FormOrderType.Filler && order?.state === FormOrderState.ReturnedToClient);
  const afterBreadcrumbs = <InputGroup size={'sm'} className={'gap-2'}>
    {signingState === FormSigningState.None && !order &&
      <Button
        variant={'link'}
        title="Annexures"
        size={'sm'}
        className="px-0 py-0 border-0 bg-transparent link-secondary"
        onClick={annexuresHandler}>
        Annexures
      </Button>}
    {showPreviewButton && !fillerReturned && <Button
      variant={'link'}
      title="Preview"
      size={'sm'}
      className="px-0 py-0 border-0 bg-transparent link-secondary"
      onClick={previewHandler}>
      Preview
    </Button>}
  </InputGroup>;
  const orderPreparedAt = order?.job?.preparedAtMs || 0;
  const msSinceOrderPrepared = Date.now() - orderPreparedAt;
  // 6 months = 1000 * 60 * 60 * 24 * 30 * 6 = 15552000000 ms
  const orderRecentEnough = msSinceOrderPrepared < 15552000000;
  const afterTitle = <>
    <UpdatesPendingBadge propertyId={backgroundJobsContext?.propertyId} headline={headlineVal}
      show={backgroundJobsContext.envelopeChangesAvailable} className='ms-auto me-2' />
    {form?.archived && <Button variant='outline-secondary' className='d-none d-md-block'
      onClick={handleUnarchiveClick}>Unarchive</Button>}
    {order?.type === FormOrderType.Agent && orderRecentEnough && order?.state === FormOrderState.ReturnedToClient && order?.job?.id && !form.archived && <>
      <Button variant="outline-secondary" onClick={() => {
        const params = new URLSearchParams(location.search);
        params.set('view_order', '1');
        navigate(`${location.pathname}?${params.toString()}`);
      }}>View Order</Button>
      <Button variant="outline-secondary" onClick={() => setShowReturnModal(true)}>Return</Button>
    </>}
    {(!order && formCode === SubscriptionFormCode.SAF001V2_Form1) && !form.archived &&
      <Button variant={'outline-secondary'} onClick={handleOrderClick}
        disabled={!localEntity.epfAgencyId}>Order</Button>}
    {order?.type === FormOrderType.Filler
      && [FormOrderState.ThirdPartyPreparing, FormOrderState.ReturnedToThirdParty].includes(order?.state || FormOrderState.None)
      && [FormSigningState.None, FormSigningState.Signed].includes(form.signing?.state || FormSigningState.None)
      && !form.archived && <>
      <AsyncButton variant="outline-secondary" onClick={() => setShowReleaseModal(true)}>Release</AsyncButton>
    </>}
    {signingState === FormSigningState.None && !fillerReturned && !form.archived &&
      <Button
        variant="primary"
        className='py-2'
        disabled={!iframeLoaded}
        onClick={() => {
          startSigningConfiguration({
            store,
            formId,
            formCode,
            sessionInfo,
            metaBinder,
            dataBinder,
            authRep: agent,
            entitySigningOpts: localEntity.signingOptions
          });
        }}>
        Signing
      </Button>}
    {showConfiguration && !fillerReturned &&
      <Button
        variant="outline-secondary"
        onClick={cancelSigningHandler}>
        Cancel Signing
      </Button>}
  </>;

  const useFlexLayout = showConfiguration || showSigningSession;

  return <div className="subscription-document-edit-page d-flex">
    {showReturnModal && <ErrorBoundary fallbackRender={fallback => <FallbackModal {...fallback} show={showReturnModal}
      onClose={() => setShowReturnModal(false)} />}>
      <ReturnToPreparerModal onClose={() => setShowReturnModal(false)} />
    </ErrorBoundary>}
    {showReleaseModal && <ErrorBoundary fallbackRender={fallback => <FallbackModal {...fallback} show={showReleaseModal}
      onClose={() => setShowReleaseModal(false)} />}>
      <ReleaseToAgentModal onClose={() => setShowReleaseModal(false)} />
    </ErrorBoundary>}
    {showUnArchiveModal && <ErrorBoundary
      fallbackRender={fallback => <FallbackModal {...fallback} show={showUnArchiveModal}
        onClose={() => setShowUnArchiveModal(false)} />}>
      <Form1UnarchiveModal onClose={() => setShowUnArchiveModal(false)} />
    </ErrorBoundary>}
    <ContentTitler
      breadcrumbs={breadcrumbs}
      title={title}
      titleBadge={titleBadge}
      afterBreadcrumbs={afterBreadcrumbs}
      afterTitle={afterTitle}
      className={useFlexLayout ? 'overflow-auto' : ''}
      flex={useFlexLayout}
      scroll={useFlexLayout}>
      {order?.pdfPreview && order?.state === FormOrderState.ReturnedToClient && order.type === FormOrderType.Filler
        ?
        <SetupPdfLoadStateContext>
          <PDFViewer
            filename={`${headlineVal} - ${FormTypes[formCode].label}.pdf`}
            pdfUrl={pdfPreviewUrl}
            bookmark={''}
            allowPrint={true}
            offsetRightByDragBar={allotmentPaneMode === AllotmentPaneMode.Both}
            renderTextLayer={renderTextLayer}
            standalonePreview={true}
          />
        </SetupPdfLoadStateContext>
        :
        <>
          {signingState === FormSigningState.None &&
            <EditSubscriptionForm
              ref={editSubscriptionFormRef}
              formId={formId}
              formCode={formCode}
              yDoc={yDoc}
              onIframeLoaded={() => setIframeLoaded(true)}
            />}
          {showConfiguration &&
            <FormContextSigningOverride>
              <SigningConfiguration
                propertyId={transId}
                formCode={formCode}
                formId={formId}
                ydoc={yDoc}
                yMetaRootKey={MasterRootKey.Meta} // Subscription forms do not yet support sublineages
                yRootKey={MasterRootKey.Data}
                entityLogoLoadedUri={entityLogoUri}
                prepareForSigning={prepareForSigningHandler}
              />
            </FormContextSigningOverride>}

          {showSigningSession && <SubscriptionFormOutForSigning
            yDoc={yDoc}
            formInstance={form}
            onCancel={cancelSigningHandler}
          />}
        </>
      }
      <ErrorBoundary fallbackRender={fallback => <FallbackModal {...fallback} show={showOrderConfirmDialog}
        onClose={() => setShowOrderConfirmDialog(false)} />}>
        <OrderConfirmDialog onHide={() => setShowOrderConfirmDialog(false)} show={showOrderConfirmDialog}
          onOrder={handleOrderConfirmClick} />
      </ErrorBoundary>
    </ContentTitler>
  </div>;
}

function OrderConfirmDialog({ show, onHide, onOrder }: { show: boolean, onHide: () => void, onOrder: () => void }) {
  return <Modal show={show} size='lg' onHide={onHide}>
    <Modal.Header closeButton><Modal.Title>There is already a Form 1 document in this property
      folder</Modal.Title></Modal.Header>
    <Modal.Body>
      <p>Ordering a Form 1 from Eckermann Property Forms will cause the existing document to be deleted.</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={onHide}>Cancel</Button>
      <Button variant={'danger'} onClick={() => {
        onHide();
        onOrder();
      }}>Delete & Order</Button>
    </Modal.Footer>
  </Modal>;
}
