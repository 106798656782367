import 'allotment/dist/style.css';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import React, { useContext, useState } from 'react';
import {
  AllotmentPaneMode,
  useContentPreviewAllotmentState
} from '@property-folders/components/hooks/useContentPreviewAllotmentState';
import { Allotment, LayoutPriority } from 'allotment';
import clsJn from '@property-folders/common/util/classNameJoin';
import { createPortal } from 'react-dom';
import { Button, Container } from 'react-bootstrap';
import { EmailSettingsEdit, EmailSettingsPreview } from '~/pages/settings/tabs/EmailSettings';
import {
  ReaformsBrandingSettingsEdit,
  ReaformsBrandingSettingsPreview
} from '~/pages/settings/tabs/ReaformsBrandingSettings';
import {
  GreatformsBrandingSettingsEdit,
  GreatformsBrandingSettingsPreview
} from '~/pages/settings/tabs/GreatformsBrandingSettings';
import { PortalSettingsEdit, PortalSettingsPreview } from '~/pages/settings/tabs/PortalSettings';
import {
  PortalSelfRegistrationSettingsEdit,
  PortalSelfRegistrationSettingsPreview
} from '~/pages/settings/tabs/PortalSelfRegistrationSettings';
import { RulesSettingsEdit } from '~/pages/settings/tabs/RulesSettings';
import { DisclosureOfBenefitsEdit } from '~/pages/settings/tabs/DisclosureOfBenefits';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { ShortId } from '@property-folders/common/util/url';
import { useNavigate } from 'react-router-dom';
import { UserManagementEdit } from '~/pages/settings/tabs/UserManagement';

export function EntitySettingsEditPage() {
  const {
    onVisibleChange,
    showContentPane,
    showPreviewPane,
    allotmentPaneMode,
    setAllotmentPaneMode,
    splitEnabled
  } = useContentPreviewAllotmentState();
  const { afterBreadcrumbs, settingsGroupTabConfig, largeScreen, entityUuid } = useContext(EntitySettingsContext);
  const [previewKey, setPreviewKey] = useState('');
  const previewEnabled = Boolean(settingsGroupTabConfig?.preview);
  const { value: settings } = useLightweightTransaction<EntitySettingsEntity>({ myPath: '' });
  const enablePf = Boolean(settings?.useNewPropertyTransactions);
  const navigate = useNavigate();

  // redirect to root page if invalid setting, or pf only setting and not pf entity
  if (!settingsGroupTabConfig || (settingsGroupTabConfig?.pfOnly && !enablePf)) {
    navigate(`/agency-settings/${ShortId.fromUuid(entityUuid)}`);
  }

  const onPreviewKey = (key: string) => {
    setPreviewKey(key);
    if (!previewEnabled) return;
    if (largeScreen) return;
    if (!key) return;
    setAllotmentPaneMode(AllotmentPaneMode.Preview);
  };

  if (!settingsGroupTabConfig) return <></>;

  const contentPane = <>{showContentPane && <div className='position-relative h-100 w-100 px-3 px-xl-0'>
    {(() => {
      switch (settingsGroupTabConfig.id) {
        case 'email':
          return <EmailSettingsEdit onPreviewKey={onPreviewKey}/>;
        case 'reaforms':
          return <ReaformsBrandingSettingsEdit onPreviewKey={onPreviewKey} />;
        case 'greatforms':
          return <GreatformsBrandingSettingsEdit onPreviewKey={onPreviewKey} />;
        case 'portal':
          return <PortalSettingsEdit onPreviewKey={onPreviewKey} />;
        case 'portal-self-registration':
          return <PortalSelfRegistrationSettingsEdit onPreviewKey={onPreviewKey} />;
        case 'rules':
          return <RulesSettingsEdit />;
        case 'disclosure-of-benefits':
          return <DisclosureOfBenefitsEdit />;
        case 'users':
          return <UserManagementEdit />;
        default:
          return <Container>Not yet implemented</Container>;
      }
    })()}
  </div>}</>;
  const previewPane = <>{showPreviewPane && previewEnabled &&
    <div className='position-relative h-100 w-100' style={{ backgroundColor: settingsGroupTabConfig.preview?.bg || 'var(--clr-reaforms-grey)' }}>
      {(() => {
        switch (settingsGroupTabConfig.id) {
          case 'email':
            return <EmailSettingsPreview previewKey={previewKey} />;
          case 'reaforms':
            return <ReaformsBrandingSettingsPreview previewKey={previewKey} />;
          case 'greatforms':
            return <GreatformsBrandingSettingsPreview previewKey={previewKey} />;
          case 'portal':
            return <PortalSettingsPreview previewKey={previewKey} />;
          case 'portal-self-registration':
            return <PortalSelfRegistrationSettingsPreview previewKey={previewKey} />;
          default:
            return <></>;
        }
      })()}
    </div>}</>;

  // breadcrumbs will manage back navigation probably.
  return <div className='w-100 h-100 d-flex flex-column'>
    {afterBreadcrumbs && createPortal(<>
      {allotmentPaneMode === AllotmentPaneMode.Preview &&
        <Button variant='link' title='Config' size='sm' className='px-0 py-0 border-0 bg-transparent link-secondary'
          onClick={() => splitEnabled
            ? setAllotmentPaneMode(AllotmentPaneMode.Both)
            : setAllotmentPaneMode(AllotmentPaneMode.Content)}>Config</Button>}
      {allotmentPaneMode === AllotmentPaneMode.Content && largeScreen && previewEnabled &&
        <Button variant='link' title='Preview' size='sm' className='px-0 py-0 border-0 bg-transparent link-secondary'
          onClick={() => splitEnabled
            ? setAllotmentPaneMode(AllotmentPaneMode.Both)
            : setAllotmentPaneMode(AllotmentPaneMode.Preview)}>Preview</Button>}
    </>, afterBreadcrumbs)}
    <div className={clsJn('alot-container position-relative h-100 w-100 d-flex')}>
      {splitEnabled
        ? <Allotment snap onVisibleChange={onVisibleChange}>
          <Allotment.Pane minSize={300} preferredSize={760} priority={LayoutPriority.High} visible={showContentPane}>
            {contentPane}
          </Allotment.Pane>
          {previewEnabled && <Allotment.Pane visible={showPreviewPane}>
            {previewPane}
          </Allotment.Pane>}
        </Allotment>
        : previewEnabled && !showContentPane
          ? previewPane
          : contentPane}
    </div>
  </div>;
}
