import './EditUserModal.scss';
import { Accordion, Button, Col, Container, FloatingLabel, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BaseAjaxResponse, LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { Maybe } from '@property-folders/contract';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import useDetectSticky from '@property-folders/components/hooks/useDetectSticky';
import clsJn from '@property-folders/common/util/classNameJoin';
import { isEqual } from 'lodash';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import Select from 'react-select';
import {
  validateChange,
  ValidationNode
} from '@property-folders/common/yjs-schema/property/validation/process-validation';
import { Avatar } from '@property-folders/components/dragged-components/signing/HostedSigning';
import { tryParseInt } from '@property-folders/common/util';

type NumericBool = 0 | 1;

interface LoadAgentResponse extends BaseAjaxResponse {
  asagent: boolean;
  entities: { EntityID: number, ManagementID: number }[];
  autoShare?: Record<number, string[]>;
  data: {
    AgentABN: string,
    AgentAddress1: string,
    AgentAddress2: string,
    AgentCompanyName: string,
    AgentEmail: string,
    AgentName: string,
    AgentPhone: string,
    AgentPostCode: string,
    AgentRLA: string,
    AgentShowLogo: NumericBool,
    AgentState: string,
    AgentSuburb: string,
    AgentTradingName: string,
    AssocAgentABN: string,
    AssocAgentCompanyName: string,
    AssocAgentRLA: string,
    CanOrder: NumericBool,
    CanTemplate: NumericBool,
    CanSharePropertyFolders?: NumericBool,
    CanShareWith?: string[],
    DC?: string,
    Form1ServiceAddressIsRLAUser: NumericBool,
    Form1ServiceAddressUser: string
    Form1ServiceFaxOrEmailUser: string
    GnafID: string,
    InitialsFile?: string,
    InitialsFileDelete?: NumericBool,
    InitialsFileModifiedAt?: string,
    IsAdmin: NumericBool,
    IsAssocAgent: NumericBool,
    IsAuctioneer: NumericBool,
    IsContractor: NumericBool,
    IsMarketingTemplateEditor: NumericBool,
    IsPropertyManager: NumericBool,
    IsSalesperson: NumericBool,
    OfflineProperties: NumericBool,
    OwnForms: NumericBool,
    OwnTemplates: NumericBool,
    PromoteECUser?: NumericBool,
    SignatureFile?: string,
    SignatureFileDelete?: NumericBool,
    SignatureFileModifiedAt?: string,
  };
}

export function EditUserModal({
  onClose,
  agentId,
  onSave
}: {
  onClose: () => void,
  onSave: () => Promise<void>
  agentId: number
}) {
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [init, setInitData] = useState<Maybe<LoadAgentResponse>>(undefined);
  const [latest, setLatestData] = useState<Maybe<LoadAgentResponse>>(undefined);
  const [headerIsStuck, headerRef] = useDetectSticky(undefined, undefined, false);
  const [footerIsStuck, footerRef] = useDetectSticky(undefined, undefined, true);
  const formRef = useRef<HTMLFormElement>(null);
  const unchanged = useMemo(() => {
    return isEqual(init, latest);
  }, [init, latest]);
  const {
    selectedEntities,
    selectedEntityIds,
    flagContractors,
    flagGreatforms,
    flagStateSa,
    flagEpfLinked
  } = useMemo(() => {
    if (!entityPhpInfo?.entities) return { selectedEntities: [] };
    if (!latest?.entities) return { selectedEntities: [] };

    const selectedEntities = entityPhpInfo.entities.filter(phpEntity => latest.entities.find(e => e.EntityID === phpEntity.entityId));

    return {
      selectedEntities,
      selectedEntityIds: selectedEntities.map(e => e.entityId),
      flagContractors: Boolean(selectedEntities.find(e => e.allowContractors)),
      flagGreatforms: Boolean(selectedEntities.find(e => e.useNewPropertyTransactions)),
      flagStateSa: Boolean(selectedEntities.find(e => e.state?.toLowerCase() === 'sa')),
      flagEpfLinked: Boolean(selectedEntities.find(e => e.epfAgencyId))
    };
  }, [latest, entityPhpInfo?.entities]);

  useEffect(() => {
    LegacyApi.ajax<LoadAgentResponse>('loadagent', { AgentID: agentId.toString() })
      .then(data => {
        setInitData(data);
        setLatestData(data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const validationResult = useMemo(() => {
    return validateChange(
      latest || { data: {}, entities: [] },
      {
        _type: 'Map',
        data: {
          _type: 'Map',
          AgentName: { _type: 'string', _required: true },
          AgentEmail: { _type: 'string', _subtype: 'email', _required: true },
          AgentPhone: { _type: 'string', _subtype: 'phone' },
          AgentABN: { _type: 'string', _subtype: 'abnacn' },
          AssocAgentABN: { _type: 'string', _subtype: 'abnacn' }
        },
        entities: {
          _type: 'Array',
          _children: {
            _type: 'Map'
          }
        }
      },
      {
        data: {
          AgentName: { _required: true },
          AgentEmail: { _required: true }
        },
        entities: {
          _minimum: 1
        }
      },
      {},
      []
    );
  }, [latest]);
  const valid = validationResult._validationResult.valid;
  if (!valid) {
    // frontend debugging
    console.error('validationResult', validationResult);
  }

  const setLatestDataField = (key: keyof LoadAgentResponse['data'], value: string | NumericBool | string[]) => {
    setLatestData(cur => {
      if (!cur) return cur;
      const clone = structuredClone(cur);
      // @ts-ignore
      clone.data[key] = value;
      return clone;
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!valid) {
      return;
    }

    setSaving(true);
    setErrorMessage('');
    AjaxPhp.adminSaveAgent({
      agentId,
      // @ts-ignore
      formData: new FormData(e.currentTarget)
    })
      .then(response => {
        if (response?.success) {
          return onSave();
        }

        if (response?.message) {
          setErrorMessage(response.message);
          return;
        }

        console.error(response);
        setErrorMessage('There was an error saving changes. Please try again.');
      })
      .catch(console.error)
      .finally(() => setSaving(false));
  };

  function getValidationErrors(...path: string[]) {
    let current = validationResult;
    for (const item of path) {
      current = current?.[item];
    }
    return (current as ValidationNode | undefined)
      ?._validationResult
      ?.errors || [];
  }

  function getValidationErrorsMulti(...paths: string[][]) {
    return paths.flatMap(path => getValidationErrors(...path));
  }

  function makeText(
    label: string,
    type: string,
    name: keyof LoadAgentResponse['data']
  ) {
    if (!latest) return <></>;

    const errors = getValidationErrors('data', name);

    return <FloatingLabel label={label}>
      <Form.Control
        type={type}
        name={name}
        placeholder={''}
        defaultValue={latest.data[name]}
        onChange={e => {
          setLatestDataField(
            e.currentTarget.name as keyof LoadAgentResponse['data'],
            e.currentTarget.value);
        }}
        isInvalid={Boolean(errors.length)}
      />
      <ErrorLabel label={label || ''} errors={errors}/>
    </FloatingLabel>;
  }

  function makeCheck(
    name: keyof LoadAgentResponse['data'],
    label: string
  ) {
    if (!latest) return <></>;

    return <Form.Check
      type='checkbox'
      id={name}
      name={name}
      label={label}
      value='1'
      defaultChecked={latest.data[name] == '1'}
      onChange={e => {
        setLatestDataField(
          e.currentTarget.name as keyof LoadAgentResponse['data'],
          e.currentTarget.checked ? 1 : 0);
      }}
    />;
  }

  const showEcIntegration = Boolean(flagGreatforms && flagStateSa);
  const showEpfIntegration = Boolean(flagStateSa && latest?.data?.IsSalesperson == 1 && flagEpfLinked);

  return <Modal show={true} onHide={saving ? undefined : onClose} size='lg'>
    {loading
      ? <>
        <Modal.Header>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center align-items-center p-5'>
          <Spinner animation='border'/>
        </Modal.Body>
        <Modal.Footer>
          <Button /*disabled={saving}*/ variant='outline-secondary' onClick={onClose}>Cancel</Button>
        </Modal.Footer>
      </>
      : (init && latest) ? <Form ref={formRef} onSubmit={onSubmit} noValidate>
        <Modal.Header ref={headerRef} className={clsJn('sticky bg-white', headerIsStuck && 'stuck')} data-stick='top'>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion defaultActiveKey='0' flush alwaysOpen>
            <QuickAccordionItem
              eventKey={0}
              title='Profile'
              afterTitle={<ErrorLabel errors={getValidationErrorsMulti(
                ['data', 'AgentName'],
                ['data', 'AgentEmail'],
                ['data', 'AgentPhone'],
              )} message='Validation errors found.' />}
            >
              <Container fluid>
                <Row>
                  <Col xs={12} sm={6}>
                    {makeText('Name', 'text', 'AgentName')}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6} className={'mt-2'}>
                    {makeText('Email', 'email', 'AgentEmail')}
                  </Col>
                  <Col xs={12} sm={6} className={'mt-2'}>
                    {makeText('Mobile', 'text', 'AgentPhone')}
                  </Col>
                </Row>
              </Container>
            </QuickAccordionItem>
            {entityPhpInfo?.entities?.length && entityPhpInfo.entities.length > 1 && <QuickAccordionItem
              eventKey={1}
              title='Agency access'
              afterTitle={<ErrorLabel
                label='entities'
                errors={getValidationErrors('entities')}
                message={'Agent must belong to at least one Entity'}/>}
            >
              <p>If you operate with multiple Agencies in reaforms, you can assign users to all or some of them.</p>
              <p>If a user is not assigned to an Agency, they cannot see or create any of the content in that
                Agency.</p>
              <div className='d-flex flex-column gap-2'>
                {entityPhpInfo.entities.map(entity => {
                  return <Form.Check
                    key={entity.entityId}
                    type={'checkbox'}
                    id={`Entity_${entity.entityId}`}
                    name={`Entity_${entity.entityId}`}
                    value={entity.entityId}
                    disabled={init.asagent}
                    defaultChecked={Boolean(init.entities.find(e => e.EntityID === entity.entityId))}
                    label={<EntityCheckLabel {...entity} />}
                    onChange={e => {
                      const checked = e.currentTarget.checked;
                      setLatestData(cur => {
                        if (!cur) return cur;
                        if (checked && cur.entities.find(e => e.EntityID === entity.entityId)) return cur;
                        if (!checked && !cur.entities.find(e => e.EntityID === entity.entityId)) return cur;

                        const clone = structuredClone(cur);
                        if (checked) {
                          clone.entities.push({ EntityID: entity.entityId, ManagementID: entity.managementId });
                        } else {
                          clone.entities = clone.entities.filter(e => e.EntityID !== entity.entityId);
                        }
                        return clone;
                      });
                    }}
                  />;
                })}
              </div>
            </QuickAccordionItem>}
            <QuickAccordionItem eventKey={2} title='Subscription permissions'>
              <Container fluid>
                <Row>
                  <Col xs={12} sm={6}>
                    {makeCheck('CanTemplate', 'Edit document templates')}
                  </Col>
                  <Col xs={12} sm={6}>
                    {makeCheck('OwnForms', 'View only documents they create')}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6}>
                    {makeCheck('IsMarketingTemplateEditor', 'Edit marketing templates')}
                  </Col>
                  <Col xs={12} sm={6}>
                    {makeCheck('OwnTemplates', 'View only templates they create')}
                  </Col>
                </Row>
              </Container>
            </QuickAccordionItem>
            {flagContractors && <QuickAccordionItem
              eventKey={3}
              title='Subscription roles'
              afterTitle={<ErrorLabel errors={getValidationErrorsMulti(
                ['data', 'AgentABN']
              )} message='Validation errors found.' />}
            >
              <p>A contractor is a non-employee operating within the reaforms subscription. Contractors can be
                white-labelled so their legal documents carry the contractor's agency details and branding:</p>
              <Row className='mt-2'>
                <Col>
                  {makeCheck('IsContractor', 'This user is a contractor')}
                </Col>
              </Row>
              {Boolean(latest.data.IsContractor) && <>
                <Row>
                  <Col xs={12} sm={6} className='mt-2'>
                    {makeText('Contractor\'s Company Name', 'text', 'AgentCompanyName')}
                  </Col>
                  <Col xs={12} sm={6} className='mt-2'>
                    {makeText('Contractor\'s Trading Name', 'text', 'AgentTradingName')}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6} className='mt-2'>
                    {makeText('Contractor\'s ABN', 'text', 'AgentABN')}
                  </Col>
                  <Col xs={12} sm={6} className='mt-2'>
                    {makeText('Contractor\'s RLA/Agent Registration', 'text', 'AgentRLA')}
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col>
                    {makeCheck('AgentShowLogo', 'Show Agency logo on documents')}
                  </Col>
                </Row>
              </>}
            </QuickAccordionItem>}
            {flagGreatforms && <QuickAccordionItem
              eventKey={4}
              title='Greatforms roles'
              afterTitle={<ErrorLabel errors={getValidationErrorsMulti(
                ['data', 'AssocAgentABN']
              )} message='Validation errors found.' />}
            >
              <p>A Salesperson can sign legal documents on behalf of the Agency in sales transactions:</p>
              {makeCheck('IsSalesperson', 'This user is a Salesperson')}
              <p className='mt-3'>When this user is assigned as a Salesperson, Greatforms can automatically share those
                property folders with a team:</p>
              {selectedEntities?.map((entity, index) => {
                return <div key={entity.entityId} className={index ? 'mt-3' : undefined}>
                  <h6>{entity.displayName}</h6>
                  <UserTeamSelector
                    name={`AutoShare_${entity.entityId}`}
                    entityIds={[entity.entityId]}
                    agentId={agentId}
                    defaultValue={latest.autoShare?.[entity.entityId] || []}
                    onChange={values => {
                      setLatestData(cur => {
                        if (!cur) return cur;

                        const clone = structuredClone(cur);
                        if (!clone.autoShare) {
                          clone.autoShare = {};
                        }
                        clone.autoShare[entity.entityId] = values;
                        return clone;
                      });
                    }}
                  />
                </div>;
              })}
              <hr/>
              <p>A Property Manager can sign legal documents on behalf of the Agency in property management
                transactions:</p>
              {makeCheck('IsPropertyManager', 'This user is a property manager')}
              <hr/>
              <p>An Auctioneer can sign legal documents with the authority of a Licensed Auctioneer:</p>
              {makeCheck('IsAuctioneer', 'This user is a Licensed Auctioneer')}
              <hr/>
              <p>An Associated Agent operates an independent business within a licensed Agency:</p>
              {makeCheck('IsAssocAgent', 'This user is an Associated Agent')}
              {Boolean(latest.data.IsAssocAgent) && <>
                <Row>
                  <Col className='mt-2'>
                    {makeText('Associated Agent\'s Company Name', 'text', 'AssocAgentCompanyName')}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6} className='mt-2'>
                    {makeText('Associated Agent\'s ABN', 'text', 'AssocAgentABN')}
                  </Col>
                  <Col xs={12} sm={6} className='mt-2'>
                    {makeText('Associated Agent\'s RLA/Agent Registration', 'text', 'AssocAgentRLA')}
                  </Col>
                </Row>
              </>}
            </QuickAccordionItem>}
            {flagGreatforms && <QuickAccordionItem eventKey={5} title='Greatforms preferences'>
              <h5>Offline access</h5>
              <p>Greatforms supports many ways or working, including the preparation of legal documents without an
                Internet connection. When offline is enabled, a users browser will store changes made by the user, and
                synchronise them to the Greatforms servers once an Internet connection is available.</p>
              <p>Some features do not work offline including:</p>
              <ul>
                <li>Land Services SA integration.</li>
                <li>Remote signing.</li>
                <li>On screen signing with SMS verification.</li>
              </ul>
              {makeCheck('OfflineProperties', 'Enable access to Greatforms when offline')}
            </QuickAccordionItem>}
            {Boolean(latest.data.SignatureFile || latest.data.InitialsFile) &&
              <QuickAccordionItem eventKey={6} title='Stored signature / initials'>
                <div className='d-flex flex-column gap-3'>
                  {latest.data.SignatureFile && <div>
                    {makeCheck('SignatureFileDelete', 'Remove signature')}
                    {latest.data.SignatureFileDelete != 1 && <>
                      <div style={{ height: '50px' }}>
                        <img src={latest.data.SignatureFile} style={{ height: '100%', objectFit: 'contain' }}/>
                      </div>
                      {latest.data.SignatureFileModifiedAt &&
                        <div>Modified: {latest.data.SignatureFileModifiedAt}</div>}
                    </>}
                    {latest.data.SignatureFileDelete == 1 && <span>
                    reaforms will prompt for a new signature during signing, if required.
                    </span>}
                  </div>}
                  {latest.data.InitialsFile && <div>
                    {makeCheck('InitialsFileDelete', 'Remove initials')}
                    {latest.data.InitialsFileDelete != 1 && <>
                      <div style={{ height: '50px' }}>
                        <img src={latest.data.InitialsFile} style={{ height: '100%', objectFit: 'contain' }}/>
                      </div>
                      {latest.data.InitialsFileModifiedAt && <div>Modified: {latest.data.InitialsFileModifiedAt}</div>}
                    </>}
                    {latest.data.InitialsFileDelete == 1 && <span>
                    reaforms will prompt for new initials during signing, if required.
                    </span>}
                  </div>}
                </div>
              </QuickAccordionItem>}
            {Boolean(showEcIntegration || showEpfIntegration) &&
              <QuickAccordionItem eventKey={7} title='Integration preferences'>
                {showEcIntegration && <>
                  <h5>Eckermann Conveyancers</h5>
                  <p>Eckermann Conveyancers is South Australia's premiere conveyancer, and part of the same group of
                    companies that brings you Greatforms. Our conveyancers have extensive knowledge of the legal content
                    of reaforms, and are excellent partners to the agencies using Greatforms. </p>
                  <p>After signing documents, Greatforms can ask Vendors and Purchasers for consent to discuss their
                    conveyancing needs with Eckermann Conveyancers.</p>
                  <Row>
                    <Col sm={6}>
                      <FloatingLabel label='Promote Eckermann Conveyancers'>
                        <Form.Select
                          name='PromoteECUser'
                          defaultValue={getYesNoNoneOptionValue(latest.data.PromoteECUser)}
                          onChange={e => setLatestDataField('PromoteECUser', getYesNoNoneFieldValue(e.target.value))}
                        >
                          <option value='1'>Yes</option>
                          <option value=''>No preference</option>
                          <option value='0'>No</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                  </Row>
                </>}
                {showEpfIntegration && <>
                  <h5 className='mt-4'>Eckermann Property Forms</h5>
                  <p>Eckermann Property Forms has extensive integration with reaforms and Greatforms, making it easy for
                    you to order premium Form 1 preparation for your sales transactions. </p>
                  <h6>Methods of Service</h6>
                  <p>You can customise the information that our team will enter into the Form 1 under the Methods of
                    Service for cooling off notices when this user is selected as the Salesperson.</p>

                  <Row>
                    <Col xs={12} sm={6}>
                      {makeText('Fax or email address for notices', 'text', 'Form1ServiceFaxOrEmailUser')}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} className='mt-2'>
                      {makeText('Address for notices', 'text', 'Form1ServiceAddressUser')}
                    </Col>
                    <Col xs={12} sm={6} className='mt-2 align-content-center'>
                      {makeCheck('Form1ServiceAddressIsRLAUser', 'This address is the RLA address')}
                    </Col>
                  </Row>
                </>}
              </QuickAccordionItem>}
            {flagGreatforms && <QuickAccordionItem eventKey={8} title='Greatforms Sharing'>
              <p>Greatforms property folders and documents are private to the user that created them by default, but
                they can elect to share those documents with other users and teams.</p>
              <Container fluid>
                <Row>
                  <Col>
                    {makeCheck('CanSharePropertyFolders', 'This user can share property folders and documents with any other users and teams')}
                  </Col>
                </Row>
                {latest.data.CanSharePropertyFolders == 1 && <Row>
                  <Col xs={12}>
                    Select the users and teams that this user can share with:
                    <UserTeamSelector
                      name={'CanShareWith'}
                      defaultValue={latest.data.CanShareWith || []}
                      entityIds={selectedEntityIds || []}
                      agentId={agentId}
                      onChange={values => {
                        setLatestDataField('CanShareWith', values);
                      }}
                    />
                  </Col>
                </Row>}
              </Container>
            </QuickAccordionItem>}
          </Accordion>
        </Modal.Body>
        <Modal.Footer ref={footerRef} className={clsJn('sticky bg-white', footerIsStuck && 'stuck')} data-stick='bot'>
          {errorMessage && <span className='text-danger'>{errorMessage}</span>}
          <Button disabled={saving} variant='outline-secondary' onClick={onClose}>Cancel</Button>
          <SpinnerButton type='submit' processing={saving} disabled={unchanged || !valid}>Save</SpinnerButton>
        </Modal.Footer>
      </Form> : <div>Failed to load</div>}
  </Modal>;
}

function getYesNoNoneOptionValue(value?: string | NumericBool) {
  if (value == '1') return '1';
  if (value == '0') return '0';
  return '';
}

function getYesNoNoneFieldValue(value?: string) {
  switch (value) {
    case '1':
      return 1;
    case '0':
      return 0;
    default:
      return '';
  }
}

function QuickAccordionItem({ eventKey, title, children, afterTitle }: React.PropsWithChildren<{
  eventKey: string | number,
  title: string,
  afterTitle?: React.ReactNode
}>) {
  return <Accordion.Item eventKey={eventKey.toString()}>
    <Accordion.Header>
      <div className='w-100 me-2 d-flex flex-row justify-content-between align-items-baseline'>
        <h6>{title}</h6>{afterTitle}</div>
    </Accordion.Header>
    <Accordion.Body>{children}</Accordion.Body>
  </Accordion.Item>;
}

function EntityCheckLabel(props: { displayName: string, rla?: string, abnAcn?: string }) {
  return <div className='d-flex flex-column'>
    <strong>{props.displayName}</strong>
    <div className='d-flex flex-row gap-3 text-muted'>
      <small>RLA: {props.rla || '-'}</small><small>ABN/ACN: {props.abnAcn || '-'}</small></div>
  </div>;
}

function UserTeamSelector(props: {
  name: string,
  defaultValue: string[],
  onChange: (values: string[]) => void,
  entityIds: number[],
  agentId: number
}) {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState<string[]>(props.defaultValue);
  const [options, setOptions] = useState<{ value: string, label: string }[]>([]);

  useEffect(() => {
    setLoading(true);
    AjaxPhp.getEntitiesUserTeams({ entityIds: props.entityIds, agentId: props.agentId })
      .then(data => setOptions(data?.items || []))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [props.entityIds.join(':'), props.agentId]);

  return <>
    <select
      hidden
      name={`${props.name}[]`}
      value={value}
      multiple
      // @ts-ignore
      readOnly
    >
      {options.map(opt => (<option key={opt.value} value={opt.value}>{opt.label}</option>))}
    </select>
    <Select
      isMulti
      isClearable
      placeholder={'Select users or teams'}
      isLoading={loading}
      options={options}
      value={options.filter(o => value.includes(o.value))}
      className='user-team-selector'
      formatOptionLabel={data => {
        const prefix = data.value.substring(0,2);
        const id = tryParseInt(data.value.substring(2), undefined);
        return <Avatar
          name={data.label}
          entityName={''}
          agentId={prefix === 'a_' ? id : undefined}
        />;
      }}
      onChange={newValue => {
        const newValues = newValue.map(v => v.value);
        setValue(newValues);
        props.onChange(newValues);
      }}
      styles={{
        menu: base => ({
          ...base,
          zIndex: 10
        })
      }}
    />
  </>;
}

function ErrorLabel({ label: labelRaw, errors, message: messageOverride }: {
  label?: string,
  errors: string[],
  message?: string
}) {
  const first = errors.at(0);
  if (!first) return <></>;

  const label = labelRaw || 'This';
  const message = (() => {
    if (messageOverride) return messageOverride;
    switch (first) {
      case 'required':
        return `${label} is required.`;
      case 'abnacn':
        return `${label} must be a valid ABN / ACN.`;
      case 'email':
        return `${label} must be a valid email address.`;
      case 'phone':
        return `${label} must be a valid phone number.`;
      default:
        return `${first} unhandled error type.`;
    }
  })();

  return <small className='text-danger'>{message}</small>;
}
