import { AwarenessData } from '@property-folders/contract';
import { initialPresence } from '~/pages/TransactionHomePage';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { RouterData } from '~/App';
import { SetupNetStateContext } from '@property-folders/components/dragged-components/NetStateContext';
import { SetupNetStateWritingYjsDocContext } from '@property-folders/components/form-gen-util/yjsStore';
import { MasterRootKey } from '@property-folders/contract/yjs-schema/property';
import { RoomProvider } from '@y-presence/react';
import { SetupDebouncedAwarenessContext } from '@property-folders/components/context/DebouncedAwarenessContext';
import { Outlet } from 'react-router-dom';

export function SubscriptionFolder() {
  const { transId, ydoc, awareness } = useReactRouterData<RouterData>();

  return ydoc && awareness
    ? <SetupNetStateContext ydoc={ydoc} transactionRootKey={MasterRootKey.Data}>
      <SetupNetStateWritingYjsDocContext
        ydoc={ydoc}
        awareness={awareness}
        docName={transId}
        transactionRootKey={MasterRootKey.Data}
        transactionMetaRootKey={MasterRootKey.Meta}
      >
        <RoomProvider<AwarenessData> awareness={awareness} initialPresence={initialPresence}>
          <SetupDebouncedAwarenessContext delay={0}>
            <Outlet></Outlet>
          </SetupDebouncedAwarenessContext>
        </RoomProvider>
      </SetupNetStateWritingYjsDocContext>
    </SetupNetStateContext>
    : <></>;
}
